import {distanceBetween} from '../coordinates';
import type {Coordinates, LayoutRect, UniqueIdentifier} from '../../types';

import type {CollisionDetection} from './types';

/**
 * Returns the coordinates of the center of a given ClientRect
 */
function centerOfRectangle(
  rect: LayoutRect,
  left = rect.offsetLeft,
  top = rect.offsetTop
): Coordinates {
  return {
    x: left + rect.width * 0.5,
    y: top + rect.height * 0.5,
  };
}

/**
 * Returns the closest rectangle from an array of rectangles to the center of a given
 * rectangle.
 */
export const closestCenter: CollisionDetection = ({
  collisionRect,
  droppableContainers,
}) => {
  const centerRect = centerOfRectangle(
    collisionRect,
    collisionRect.left,
    collisionRect.top
  );
  let minDistanceToCenter = Infinity;
  let minDroppableContainer: UniqueIdentifier | null = null;

  const bankContainer = document.getElementById('bankContainer');
  // var bankContainerRect = bankContainer.getBoundingClientRect();
  // console.log("bankContainerRect")
  // console.log(bankContainerRect)

  const selectedContainer = document.getElementById('selectedContainer');
  // var selectedContainerRect = selectedContainer.getBoundingClientRect();
  // console.log(bankContainerRect)

  for (const droppableContainer of droppableContainers) {
    const {
      rect: {current: rect},
    } = droppableContainer;

    if (rect) {
      let temp_centerRect = centerRect;
      if (bankContainer && selectedContainer) {
        if (droppableContainer.id === 'selectedContainer') {
          temp_centerRect = {
            x: centerRect.x,
            y: centerRect.y - bankContainer.scrollTop,
          };
        }

        if (droppableContainer.id === 'bankContainer') {
          temp_centerRect = {
            x: centerRect.x,
            y: centerRect.y - selectedContainer.scrollTop,
          };
        }
      }

      const distBetween = distanceBetween(
        centerOfRectangle(rect),
        temp_centerRect
      );

      if (distBetween < minDistanceToCenter) {
        minDistanceToCenter = distBetween;
        minDroppableContainer = droppableContainer.id;
      }
    }
  }

  console.log('minDroppableContainer');
  console.log(minDroppableContainer);

  return minDroppableContainer;
};
