// import {
//   add as getAdjustedCoordinates,
//   subtract as getCoordinatesDelta,
// } from '@dnd-kit/utilities';

// import type {Coordinates} from '../../types';
export type Coordinates = {
  x: number;
  y: number;
};

import {
  defaultCoordinates,
  getBoundingClientRect,
  // getOwnerDocument,
  // getWindow,
  // getScrollPosition,
  // getScrollElementRect,
} from '../../utilities';
// import {Listeners} from '../utilities';
// import {EventName} from '../events';
import type {SensorInstance, SensorProps, SensorOptions} from '../types';

// import {NamanCoordinateGetter, NamanCode, NamanCodes} from './types';
import type {NamanCodes} from './types';
import {defaultNamanCodes} from './defaults';
// import {defaultNamanCodes, defaultNamanCoordinateGetter} from './defaults';

export interface NamanSensorOptions extends SensorOptions {
  NamanCodes?: NamanCodes;
  // coordinateGetter?: NamanCoordinateGetter;
  scrollBehavior?: ScrollBehavior;
  onActivation?({event}: {event: KeyboardEvent}): void;
}

export type NamanSensorProps = SensorProps<NamanSensorOptions>;

export class NamanSensor implements SensorInstance {
  public autoScrollEnabled = false;
  // @ts-ignore
  private coordinates: Coordinates = defaultCoordinates;
  // private listeners: Listeners;
  // private windowListeners: Listeners;

  constructor(private props: NamanSensorProps) {
    // const {
    //   event: {target},
    // } = props;

    this.props = props;
    // this.listeners = new Listeners(getOwnerDocument(target));
    // this.windowListeners = new Listeners(getWindow(target));
    // this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.attach();
  }

  private attach() {
    this.handleStart();

    console.log('attached');
    const selected_container = document.getElementById('selectedContainer');
    if (selected_container) {
      selected_container.scrollTop = 0;
      if (selected_container.scrollTop == 0) {
        var rect = selected_container.getBoundingClientRect();

        const newCoordinates = {
          x: rect.left + 50,
          y: rect.top + 20,
        };

        console.log(newCoordinates);

        this.handleMove(newCoordinates);
        setTimeout(() => this.handleEnd(), 500);

        // let audio = new Audio("/assets/audio/UI_Quirky_48.mp3");
        // audio.play();
      }
    }

    // this.windowListeners.add(EventName.Resize, this.handleCancel);
    // this.windowListeners.add(EventName.VisibilityChange, this.handleCancel);

    // setTimeout(() => this.listeners.add(EventName.Keydown, this.handleKeyDown));
  }

  private handleStart() {
    const {activeNode, onStart} = this.props;

    if (!activeNode.node.current) {
      throw new Error('Active draggable node is undefined');
    }

    const activeNodeRect = getBoundingClientRect(activeNode.node.current);
    const coordinates = {
      x: activeNodeRect.left,
      y: activeNodeRect.top,
    };

    this.coordinates = coordinates;

    onStart(coordinates);
  }

  private handleMove(coordinates: Coordinates) {
    const {onMove} = this.props;

    onMove(coordinates);
    this.coordinates = coordinates;
  }

  private handleEnd() {
    const {onEnd} = this.props;
    this.detach();
    onEnd();
  }

  private handleCancel(event: Event) {
    const {onCancel} = this.props;

    event.preventDefault();
    this.detach();
    onCancel();
  }

  private detach() {
    // this.listeners.removeAll();
    // this.windowListeners.removeAll();
  }

  static activators = [
    {
      eventName: 'onKeyDown' as const,
      handler: (
        event: React.KeyboardEvent,
        {NamanCodes = defaultNamanCodes, onActivation}: NamanSensorOptions
      ) => {
        const {code} = event.nativeEvent;

        console.log('NamanSensor Space activator packages folder');

        if (NamanCodes.start.includes(code)) {
          event.preventDefault();

          onActivation?.({event: event.nativeEvent});

          return true;
        }

        return false;
      },
    },
  ];
}
