import {NamanCoordinateGetter, NamanCode, NamanCodes} from './types';

export const defaultNamanCodes: NamanCodes = {
  start: [NamanCode.KeyG],
  cancel: [NamanCode.Esc],
  end: [NamanCode.Space, NamanCode.Enter],
};

export const defaultNamanCoordinateGetter: NamanCoordinateGetter = (
  event,
  {currentCoordinates}
) => {
  switch (event.code) {
    case NamanCode.Right:
      return {
        ...currentCoordinates,
        x: currentCoordinates.x + 25,
      };
    case NamanCode.Left:
      return {
        ...currentCoordinates,
        x: currentCoordinates.x - 25,
      };
    case NamanCode.Down:
      return {
        ...currentCoordinates,
        y: currentCoordinates.y + 25,
      };
    case NamanCode.Up:
      return {
        ...currentCoordinates,
        y: currentCoordinates.y - 25,
      };
  }

  return undefined;
};
